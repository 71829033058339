import Header, {
  type HeaderProps,
} from '@/components/brochureV2/section/Header/Header';

export default function ChannelsHeader(props: HeaderProps) {
  return (
    <Header
      {...props}
      /**
       * TODO: Leave comments in, so we can uncomment a few months from now
       */

      // secondaryNavFn={({t, site, localizePath}) => {
      //   const alinks = [];

      //   const insta = ['en-IN'];
      //   // all but one
      //   if (!insta.includes(site.locale)) {
      //     alinks.push({
      //       text: t('nav:facebookInstagram'),
      //       url: localizePath('/facebook-instagram'),
      //       name: 'facebook-instagram',
      //     });
      //   }

      //   alinks.push({
      //     text: t('nav:googleYoutube'),
      //     url: localizePath('/google'),
      //     name: 'google-youtube',
      //   });

      //   const tikTok = ['en', 'en-CA', 'en-GB'];
      //   if (tikTok.includes(site.locale)) {
      //     alinks.push({
      //       text: 'TikTok',
      //       url: localizePath('/sell-on-tiktok'),
      //       name: 'sell-on-tiktok',
      //     });
      //   }

      //   return {
      //     sectionTitle: t('nav:salesChannels'),
      //     sectionOverviewUrl: localizePath('/channels'),
      //     pageTitle: t(`nav:${pageTitle}`),
      //     links: alinks,
      //   };
      // }}
    />
  );
}
